@import "~include-media/dist/include-media";
@import "../../styles/common";

.accordion {
  width: 100%;
  &:global(.services-class){
    .accordion__normal {
      height: auto;
      font-weight: 500;
    }
    .accordion__title {

      @include media(">=xl") {
        width: 100%;
        font-size: rem(legacy-rem(20));
        line-height: rem(legacy-rem(35));
        padding: rem(legacy-rem(20)) 0;
        font-weight: 500;
      }
    }

    .accordion__panel {
      flex-basis: 100%;

      &:last-of-type {
        .accordion__title {
          border-bottom: none;
        }
      }
    }
  }
  &.scroller {
    &__title {
      font-size: rem(legacy-rem(15));
      @include media(">=xl") {
        font-size: rem(legacy-rem(30));
      }
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $headerFont;
    font-size: rem(15);
    line-height: rem(24);
    padding: rem(legacy-rem(15)) 0;
    cursor: pointer;
    border-bottom: 1.5px solid rgba(194,194,194,0.3);
    color: #4a4a4a;
    width: 100%;

    @include media(">=xl") {
      width: 100%;
      font-size: rem(20);
      line-height: rem(35);
      padding: rem(legacy-rem(25)) 0;
    }

    &--open {
      border-bottom: 1.5px solid $secondary-red;
      color: $primary;
    }

    &__icon {
      margin-left: rem(legacy-rem(28));
    }
  }

  &__normal {
    transition: all 0.6s ease-in-out;

    @include media('>=xl') {
      height: rem(67);
    }

    &:hover {
      color: $primary;
      border-bottom: 1.5px solid #FF6274;
    }
  }

  &__panel {
    flex-basis: 100%;
    width: 100%;
    flex-direction: column;

    &:not(:first-of-type) {
      margin-top: rem(legacy-rem(5));
      margin-bottom: rem(legacy-rem(5));

      @include media(">=xl") {
        margin-top: rem(legacy-rem(10));
        margin-bottom: rem(legacy-rem(10));
      }
    }
  }

  &__content {
    color: #4a4a4a;
    padding: rem(legacy-rem(30));
    background-color: $secondary-grey;

    h3 {
      font-size: rem(16);
      line-height: rem(22);
    }

    @include media(">=xl") {
      padding: rem(legacy-rem(44)) rem(legacy-rem(40));

      h3 {
        font-family: $bodyFont;
        font-size: rem(22);
        line-height: rem(45);
        margin-bottom: rem(legacy-rem(20));
      }
    }

    > *:first-child {
      margin-top: 0;
    }

    > *:last-child {
      margin-bottom: 0;
    }

    &__wrapper {
      overflow: hidden;
    }
    &:global(.alternate-accordion-class){
      max-width: 100%;
      padding: rem(legacy-rem(30)) 0 rem(legacy-rem(10));

      @include media(">=xl") {
        padding: rem(legacy-rem(75)) rem(legacy-rem(36)) rem(legacy-rem(70));
      }
    }
  }

  &__darkmode {
    color: $white;
  }
}
.accordion__isDarkMode {
  color: $white;
  border-bottom: 1px solid $white;
}

.accordion__footer {
  color: $white;
  background-color: none;
  border-bottom: none;

  & > .accordion__title {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 33%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 10px 1.5px,;
    background-repeat: repeat-x;
    width: 100%;
    height: unset;
  }
}
