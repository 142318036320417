@use "sass:math";

@import "../../styles/common";
@import "~include-media/dist/include-media";

$flexible-content-row-gap: 1rem !default;

.root {
  flex-direction: column;
}

.column {
  // A column-row is a column that has collapsed under another column, which means we need to treat it kinda like a row
}

.larger-column {
  flex: 20%;
}

.mobileColumn {
  & > div {
    @include media("<sm") {
      justify-content: center;
      align-items: center;
    }

    & > div {
      display: flex;
    }
  }
}
