@import '~include-media/dist/include-media';
@import '../../styles/colors';
@import '../../styles/functions';

.container {
  width: 70%;
  max-width: rem(legacy-rem(1600));
  padding: rem(10) 0;
  display: block;
  justify-content: center;
  margin: 0 auto;
}

.embededMissingContainer {
  width: 100%;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  padding: 0;

  @media (min-width: map_get($breakpoints, lg)){
    padding: 0 rem(95);
  }

  @media (max-width: map_get($breakpoints, md)) {
    flex-direction: column;
  }

  .missingLeft {
    width: calc(100% / 2);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    text-align: left;
    margin-top: rem(60);

    @media (max-width: map_get($breakpoints, md)) {
      order: 2;
      text-align: center;
      margin: 0 auto;
      width: 100%;
      align-items: center;
    }

    .missingLeftContent {
      position: relative;
      width: rem(348);

      @include media("<=md") {
        > div {
          max-width: rem(250);
        }
      }

      @include media(">md") {
        > div {
          max-width: rem(250);
          margin-left: 0;
        }
      }

      @include media(">=lg") {
        width: rem(400);

        > div {
          max-width: 80%;
        }
      }

      @include media(">=xl") {
        width: rem(600);
        position: relative;
      }
    }

    h1 {
      margin: rem(20) 0 rem(35);
      @include media(">=lg") {

        margin: rem(legacy-rem(20)) 0 rem(legacy-rem(51));
      }

    }

    p {
      width: 100%;

      @include media("<=md") {
        width: 80%;
        margin: 0 auto;
      }

      @include media(">md") {
        width: 80%;
      }

      @include media(">=lg") {
        width: 57%;
      }
      @include media(">xl") {
        width: 44%;
      }

      &.internalError {

        @include media("<=md") {
          width: 100%;
          margin: 0 auto;
        }

        @include media(">md") {
          width: 93%;
        }

        @include media(">=lg") {
          width: 79%;
        }
        @include media(">xl") {
          width: 60%;
        }

      }

      a {
        color: $brand-pink;
        font-weight: $bold;
        transition: all 0.3s ease-in;

        &:hover {
          color: $primary;
          text-decoration-color: $primary;
        }
      }
    }
  }

  .sadImage {
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: center;

    @include media(">md") {
      width: 50%;
      align-items: flex-start;
    }

    @include media(">xl") {
      margin-top: rem(legacy-rem(-80));
    }

    .subtitle {
      display: block;
      flex-direction: column;
      font-family: $toolTipFont;
      position: relative;
      transform: rotate(-0.45rad) translate(#{rem(legacy-rem(-46))}, #{rem(legacy-rem(210))});
      width: rem(legacy-rem(175));
      left: rem(-30);
      top: 0;
      transition: all .5s ease-in;

      @media (max-width: map_get($breakpoints, xl)) {
        left: rem(-10);
        top: rem(-90);
        font-size: rem(12);
      }

      @media (max-width: map_get($breakpoints, lg)) {
        left: rem(10);
        transform: rotate(-0.45rad) translate(#{rem(legacy-rem(-61))}, #{rem(legacy-rem(140))});
      }

      @media (max-width: map_get($breakpoints, md)) {
        left: rem(0);
        top: rem(-60);
        transform: rotate(0.1724rad) translate(#{rem(legacy-rem(200))}, #{rem(legacy-rem(120))});
      }

      @media (max-width: map_get($breakpoints, sm)) {
        transform: rotate(0.1724rad) translate(#{rem(legacy-rem(145))}, #{rem(legacy-rem(60))});
        top: rem(-20);
        left: rem(40);
      }

      @media (max-width: map_get($breakpoints, xs)) {
        transform: rotate(0.1724rad) translate(#{rem(legacy-rem(170))}, #{rem(legacy-rem(70))});
        top: rem(-35);
        left: rem(-20);
      }

      .subtitleText {
        color: rgb($primary, 0.6);
        text-align: left;
        width: rem(175);
        font-size: rem(16);
        line-height: rem(16);

        @media (max-width: map_get($breakpoints, md)) {
          width: rem(legacy-rem(140));
        }

        @include media(">=lg") {
          width: rem(legacy-rem(190));
          font-size: rem(legacy-rem(24));
          line-height: rem(legacy-rem(21));
        }
      }
      .subtitleImage {
        align-self: center;
        fill: $brand-pink;
        margin: auto;
        transform: rotate(4.3583rad) translate(-#{rem(legacy-rem(5))}, #{rem(legacy-rem(20))});
        width: rem(legacy-rem(50));
        @media (max-width: map_get($breakpoints, md)) {
          font-size: rem(legacy-rem(16));
          width: rem(legacy-rem(20));
          transform: scale(-2,2) rotate(4.236rad) translate(#{rem(legacy-rem(5))}, #{rem(legacy-rem(-15))});
        }
      }
    }

    .sadImageSvg {
      max-width: 100%;
    }

    .imageWrapper {
      mask-image: url("../../../public/images/btnPlay-bg.svg");
      mask-size: cover;
      mask-repeat: no-repeat;
      -webkit-mask-image: url("../../../public/images/btnPlay-bg.svg");
      -webkit-mask-size: cover;
      -webkit-mask-repeat: no-repeat;

      @include media("<=md") {
        width: rem(400);
        height: rem(400);
      }

      @include media("<=sm") {
        width: rem(300);
        height: rem(300);
      }

      @include media(">md") {
        width: rem(330);
        height: rem(330);
      }

      @include media(">=lg") {
        width: rem(450);
        height: rem(450);
      }

      @include media(">xl") {
        width: rem(600);
        height: rem(600);
      }

    }
  }
}
