@import '../../styles/colors';
@import '../../styles/functions';

.button {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-size: rem(legacy-rem(22));
  line-height: rem(legacy-rem(28));
  font-weight: 400;
  transition: all 0.15s ease-in-out;

  &:hover  &__underlinecover {
    margin-top: rem(-5);
    transform: scaleX(1);
    transform-origin: 0 center;
    transition: 0.5s all;
    path {
      fill: $brand-blue;
      stroke-dasharray: 1;
      stroke-dashoffset: 1;

    }
  }

  &:focus {
    outline: none;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    width: min-content;
    white-space: nowrap;
    color: $primary;

    &:hover svg:nth-child(2) {
      path {
        fill: $brand-blue;
      }
      transform: scaleX(1);
    }
  }

  &--primary-inverse {
    color: $white;
  }

  &__icon {
    margin-left: rem(legacy-rem(15));
    margin-top: rem(legacy-rem(10));
    height: rem(legacy-rem(14));
    width: rem(legacy-rem(14));

    &:hover {
      path {
        animation: hoverColor 0.5s linear forwards;
      }
    }

    path {
      fill: $brand-pink;
      stroke: $brand-pink;
    }

    &--minus {
      path:nth-child(2) {
        display: none;
      }
    }
  }

  &__underline {
    margin-top: 0.3rem;

    path {
      fill: $brand-pink;
      stroke-dasharray: 1;
      stroke-dashoffset: 1;
    }
  }

  &__underlinecover {
    margin-top: rem(-5);
    transform: scaleX(0);
    transform-origin: 0 center;
    transition: 0.5s all;
    path {
      fill: $brand-blue;
      stroke-dasharray: 1;
      stroke-dashoffset: 1;

    }
  }

  &--center {
    margin: 0 auto;
  }

  &--secondary {
    background-color: $brand-blue;
    padding: rem(legacy-rem(15)) rem(legacy-rem(30)) rem(legacy-rem(15)) rem(legacy-rem(30));
    border-radius: rem(legacy-rem(7));
    color: $white;
    align-items: center;

    &:hover {
      color: $white;
      background-color: $dark-blue;
    }

    .button__inner {
      color: $white;
    }

    .button__icon {
      margin-top: 5px;
      path {
        fill: $white;
        stroke: $white;
      }
    }
    .button__underline,
    .button__underlinecover{
      display: none;
    }
  }
}


@keyframes hoverColor {
  100% {
    fill: $brand-blue;
  }
}
