@import "~include-media/dist/include-media";
@import '../../styles/common';

.accordionIcon {
  width: rem(14.14);
  height: rem(14.14);

  @include media(">=xl") {
    width: rem(legacy-rem(18.585));
    height: rem(legacy-rem(18.092));
  }
}

.footerAccordionIcon {
  width: rem(legacy-rem(18.585));
  height: rem(legacy-rem(15));
}


